<template>
  <div class="page">
    <HomeCarousel></HomeCarousel>
  </div>
</template>

<script>
import HomeCarousel from "../components/HomeCarousel.vue";
export default {
  title(){
    return 'Seda Deniz Mimarlık | Architecture'
  },
    components: { HomeCarousel }
}
</script>

<style>

</style>