<template>
  <div class="navbar-wrapper">
    <Topbar></Topbar>
    <div class="navbar">
      <div class="logo">
        <router-link to="/">
          <img src="../assets/logo.png" alt="logo" />
        </router-link>
      </div>
      <div class="navbar-links">
        <router-link to="/" class="navbar-element slideFromLeftAnimation">
          <!-- <Home class="navbar-icon" :size="36"></Home> -->
          <div class="navbar-title">{{ $t("navbar.home") }}</div>
        </router-link>
        <router-link to="/projects"  class="navbar-element exh slideFromLeftAnimation-delay-01" :class="[$route.path == '/projects' && $route.fullPath !== '/projects?category=fuar-standi' ? 'exh-active' : 'exh']">
          <!-- <ProjectorScreen class="navbar-icon" :size="36"></ProjectorScreen> -->
          <div class="navbar-title">{{ $t("navbar.projects") }}</div>
        </router-link>
        <router-link to="/contact" class="navbar-element slideFromLeftAnimation-delay-02">
          <!-- <Phone class="navbar-icon" :size="36"></Phone> -->
          <div class="navbar-title" >{{ $t("navbar.contact") }}</div>
        </router-link>
        <router-link to="/projects?category=fuar-standi" class="navbar-element exh slideFromLeftAnimation-delay-02" :class="[$route.fullPath == '/projects?category=fuar-standi' ? 'exh-active' : 'exh']">
          <!-- <AppsBox class="navbar-icon" :size="36"></AppsBox> -->
          <div class="navbar-title" >{{ $t("navbar.fuar-standi") }} 
          
          </div>

        </router-link>
<!--        
        <router-link to="/about" class="navbar-element slideFromLeftAnimation-delay-03">
          <Book class="navbar-icon" :size="36"></Book>
          <div class="navbar-title">{{ $t("navbar.about") }}</div>
        </router-link> -->
      </div>
    </div>
  </div>
</template>

<script>
// import Home from "vue-material-design-icons/Home.vue";

// import Phone from "vue-material-design-icons/Phone.vue";
// import ProjectorScreen from "vue-material-design-icons/ProjectorScreen.vue";
// import Book from "vue-material-design-icons/Book.vue";
// import AppsBox from "vue-material-design-icons/AppsBox.vue";
import Topbar from "./Topbar.vue";
export default {
  components: {
    Topbar
  }
  // components: {
  //   Phone,
  //   Home,
  //   ProjectorScreen,
  //   Book,
  //   AppsBox
  // },
};
</script>

<style scoped lang="scss">
.navbar-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;

  position: fixed;
  left: 0;
  top: 0;
  z-index: 11;
  background: white;
  max-width: 100vw;
  overflow: hidden;

}

.navbar {

  display: flex;
  align-items: center;
  height: fit-content;
  width: 100%;
  gap: 14px;
  padding: 4px 24px;


  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;

    img {
      width: 100%;
    }
  }

  .navbar-links {
    display: flex;
    flex-direction: row;
    gap: 14px;
    margin-left: auto;

  }


}

@media only screen and (max-width: 900px) {
  .navbar {
    padding:4px 8px ;

    .logo {
      width: 30px;
    }
  }


}

.navbar-element {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 12px 0;

  border-radius: 50%;
  position: relative;
  transition: all 0.5s ease;
}

.navbar-icon {
  color: #000;
}

.navbar-title {
  color: $dark-color;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  transition: all 0.5s ease;
  text-align: center;
}

.navbar-element:hover {
  opacity: 0.5;
  cursor: pointer;

}

.navbar-element:active {
  transform: scale(0.95);
}

@media only screen and (max-width: 900px) {
  .navbar {
    display: flex;
    flex-direction: row;
    left: 0;
    align-items: center;
    justify-content: space-around;
    height: fit-content;
    width: 100vw;
    z-index: 9;

    * {
      font-size: 8px !important;

    }

    gap:4px;
  }


}

.exh{
  .navbar-title{
    color: $dark-color !important;
   }
}
.exh-active{
  .navbar-title{
    color: $primary-color !important;
   }
}
</style>


<style lang="scss" >

  .router-link-exact-active{
   .navbar-title{
    color: $primary-color ;
   }
  }
</style>