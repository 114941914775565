<template>
  <div class="project-all-wrapper page" v-if="project">
    <div class="project-wrapper">
      <Banner :title="project.name" :image="images[0].url" :date="project.date" :overview="project.description"></Banner>
    </div>
    <div class="contacts-wrapper">
 
   
  </div>
    <div class="project-images-wrapper">
      <img
        class="project-image"
        :alt="'project image-' + index"
        v-for="(image, index) in images"
        :key="image"
        :src="image.url"
        @click="selectImage(index)"
      />
    </div>

    <div
      v-if="selectedImage != null"
      class="project-image-modal"
      @click="selectedImage = null"
    >
      <img
        :alt="'project image-' + selectedImage"
        :src="images[selectedImage].url"
      />
    </div>
  </div>
</template>

<script>
import { getProjectWithId } from "../firebase/db.js";
import Banner from "../components/Banner.vue";
export default {
  data() {
    return {
      project: null,
      images: null,
      selectedImage: null,
    };
  },

  created() {
    this.getProject();
  },

  methods: {
    async getProject() {
      this.pageLoading = true;
      const theproject = await getProjectWithId(this.$route.params.id);
      this.project = theproject[0];
      this.images = this.project.images;
      this.pageLoading = false;
    },

    selectImage(index) {
      this.selectedImage = index;
    },
  },

  computed: {
    pageLoading: {
      get() {
        return this.$store.state.pageLoading;
      },
      set(value) {
        this.$store.commit("UPDATE_PAGE_LOADING", value);
      },
    },
  },

  components: {  Banner },
};
</script>

<style scoped lang="scss">
.project-all-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 90px;
}
.project-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  max-width: 100%;

  background: gray;
 overflow: hidden;
}

.project-images-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 300px));
  gap: 50px;
  padding:0 50px;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  width: 100vw;
}

.project-description {
  display: flex;
  width: 1200px;
  max-width: 100%;
  flex-wrap: wrap;
  align-items: center;
  margin: 8px auto;
  color: whitesmoke;
  font-weight: bold;
  justify-content: center;
  text-align: center;
}

.project-date {
  display: flex;
  justify-content: center;
  width: 1200px;
  max-width: 100%;
  flex-wrap: wrap;
  align-items: center;
  margin: 8px auto;
  color: whitesmoke;
  font-weight: bold;
}
.project-image {
  width: 300px;
  height: 300px;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
  object-fit: cover;

}

.project-image:hover {
  opacity: 0.5;
  cursor: pointer;
}

.project-image-modal {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  z-index: 12;
  justify-content: center;
  align-items: center;

  img {
    max-width: 80%;
    max-height: 80%;
  }
}

.contact-adress {

  display: flex;
  max-width: 98%;
  width: fit-content;
  min-height: 60px;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  color: rgb(40,40,40);
  font-weight: bold;
  padding: 12px 24px;
  gap:8px;

}

.contacts-wrapper{
  display: flex;
  justify-content: center;
  padding: 12px 32px ;
  gap: 12px;
}
.bg-error {
  background: rgb(230, 230, 230);
}
</style>
