<template>
  <div class="app">
    <Navbar></Navbar>
    <Topbar></Topbar>
    <Loading v-if="pageLoading"></Loading>
<WhatsappLink></WhatsappLink>
    <router-view />

  </div>
</template>

<script>
import Topbar from "./components/Topbar.vue";
import Navbar from "./components/Navbar.vue";
import Loading from "./components/Loading.vue";
import WhatsappLink from "./components/WhatsappLink.vue";
export default {
  components: { Topbar, Navbar, Loading, WhatsappLink },

  computed:{
    pageLoading: {
      get() {
        return this.$store.state.pageLoading;
      },
      set(value) {
        this.$store.commit("UPDATE_PAGE_LOADING", value);
      },
    },
  }
};
</script>

<style lang="scss" scoped>


.app {
  font-family: "Roboto", sans-serif;
  background: $background-color;
  min-height: 100vh;
  color: $text-color;
}


.app::-webkit-scrollbar-track
{
	box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #f5f5f500;
}

.app::-webkit-scrollbar
{
	width: 12px;
	background-color: #f5f5f500;

}

.app::-webkit-scrollbar-thumb
{
	border-radius: 10px;
box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #555;
}

*::-webkit-scrollbar-track
{
	box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #f5f5f500;

}

*::-webkit-scrollbar
{
	width: 8px;
	background-color: #f5f5f500;
}

*::-webkit-scrollbar-thumb
{
  
	border-radius: 10px;
box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #555;
}
</style>
