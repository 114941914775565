<template>
  <div class="page">

    <Loading v-if="searchLoading || pageLoading"></Loading>
    <div class="categories-wrapper">
      <router-link class="category"
        :class="!selectedCategory && cat.slug == 'all' ? 'category-active' : selectedCategory == cat.slug ? 'category-active' : ''"
        v-for="cat in categories" :to="cat.href" :key="cat.slug">{{ $t(`category.${cat.slug}`) }}</router-link>
    </div>
    <div class="projects-wrapper " v-if="imagesArray && imagesArray.length">
      <router-link :to="'/project/' + project.id" class="project-element projectAnimation"
        v-for="(project, index) in imagesArray" :key="index">
        <div class="project-image-wrapper">
          <img class="project-image" loading="lazy" width="300" height="300"
            :alt="project?.name ? project.name + ' image' : 'Project image'" :src="project.src" />
          <div class="project-title">{{ project.name }}</div>

        </div>

      </router-link>
    </div>
    <div v-else-if="projects.length == 0 && !searchLoading" class="no-project">
      <h1>
        {{ $t('project.noProject') }}
      </h1>
    </div>
  </div>
</template>

<script>
import { getProjectIds, getProjectsByIds, getProjectCategoryIds } from "../firebase/db";
import Loading from "../components/Loading.vue";
export default {
  data() {
    return {
      projects: null,
      projectIds: null,
      queryRids: null,
      searchLoading: false,
      page: 1,
      dataPerPage: 12,
      isFront: 0,
      frontInterval: false,
      selectedCategory: null,
      categories: [
        {
          slug: "all",
          href: "/projects",
        },
        {
          slug: "ofis",
          href: "/projects?category=ofis",
        },
        {
          slug: "fuar-standi",
          href: "/projects?category=fuar-standi",
        },
        {
          slug: "ev-villa",
          href: "/projects?category=ev-villa",
        },
        {
          slug: "cafe",
          href: "/projects?category=cafe",
        },
        {
          slug: "magaza",
          href: "/projects?category=magaza",
        }
      ],
      imagesArray: null,
    };
  },
  created() {
    if (this.$route.query.category) {
      this.selectedCategory =  this.$route.query.category
    } else {
      this.selectedCategory =  this.$route.query.category
    }

    this.getAllIds()

    this.getProjects()
  },
  components: {
    Loading,
  },
  mounted() {
  },

  methods: {
    async getAllIds() {
      this.searchLoading = true;
      this.pageLoading = true;
      if (this.$route.query.category) {
        this.queryRids = await getProjectCategoryIds(this.$route.query.category)
      } else {
        this.projectIds = await getProjectIds();

      }

    },

    async getProjects() {
      this.projects = null;
      this.searchLoading = true;
      this.pageLoading = true;
      function shuffle(array) {
        let currentIndex = array.length, randomIndex;

        // While there remain elements to shuffle.
        while (currentIndex > 0) {

          // Pick a remaining element.
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex--;

          // And swap it with the current element.
          [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
        }

        return array;
      }

      if (!this.projectIds || !this.queryRids) {
        await this.getAllIds();
      }
      if (!this.selectedCategory && !this.$route.query.category) {
        let pageRids = { ...this.projectIds };
        pageRids = [...pageRids['all-projects']]
          .reverse()
          .splice((this.page - 1) * this.dataPerPage, this.dataPerPage);
        const response = await getProjectsByIds(pageRids);

        this.projects = response.results;
        const imgArray = []
        response.results.forEach((element) => {
          let images = element.images.sort(() => Math.random() - 0.5)
          imgArray.push(
            {
              src: images[0].url,
              alt: element.name,
              name: element.name,
              id: element.id
            }
          )
          if (images[1].url) {
            imgArray.push(
              {
                src: images[1].url,
                alt: element.name,
                name: element.name,
                id: element.id

              }
            )
          }
          if (images[2].url) {
            imgArray.push(
              {
                src: images[2].url,
                alt: element.name,
                name: element.name,
                id: element.id
              }
            )
          }
          if (images[3].url) {
            imgArray.push(
              {
                src: images[3].url,
                alt: element.name,
                name: element.name,
                id: element.id
              }
            )
          }
        });
        this.imagesArray = shuffle(imgArray)
        this.searchLoading = false;
        this.pageLoading = false;
      } else if (this.$route.query.category) {
        let pageRids = this.queryRids;
        console.log(pageRids, "pageRids")
        pageRids = pageRids
          .reverse()
          .splice((this.page - 1) * this.dataPerPage, this.dataPerPage);

        const response = await getProjectsByIds(pageRids);
        this.projects = response.results;
        const imgArray = []
        response.results.forEach((element) => {
          let images = element.images.sort(() => Math.random() - 0.5)
          imgArray.push(
            {
              src: images[0].url,
              alt: element.name,
              name: element.name,
              id: element.id
            }
          )
          if (images[1].url) {
            imgArray.push(
              {
                src: images[1].url,
                alt: element.name,
                name: element.name,
                id: element.id
              }
            )
          }
          if (images[2].url) {
            imgArray.push(
              {
                src: images[2].url,
                alt: element.name,
                name: element.name,
                id: element.id
              }
            )
          }
          if (images[3].url) {
            imgArray.push(
              {
                src: images[3].url,
                alt: element.name,
                name: element.name,
                id: element.id
              }
            )
          }
        });
        this.imagesArray = shuffle(imgArray)

        this.searchLoading = false;
        this.pageLoading = false;
      } else if (this.selectedCategory) {
        let pageRids = [...this.queryRids];
        pageRids = pageRids
          .reverse()
          .splice((this.page - 1) * this.dataPerPage, this.dataPerPage);

        const response = await getProjectsByIds(pageRids);
        this.projects = response.results;
        const imgArray = []
        response.results.forEach((element) => {
          let images = element.images.sort(() => Math.random() - 0.5)
          imgArray.push(
            {
              src: images[0].url,
              alt: element.name,
              name: element.name,
              id: element.id
            }
          )
          if (images[1].url) {
            imgArray.push(
              {
                src: images[1].url,
                alt: element.name,
                name: element.name,
                id: element.id
              }
            )
          }
          if (images[2].url) {
            imgArray.push(
              {
                src: images[2].url,
                alt: element.name,
                name: element.name,
                id: element.id
              }
            )
          }
          if (images[3].url) {
            imgArray.push(
              {
                src: images[3].url,
                alt: element.name,
                name: element.name,
                id: element.id
              }
            )
          }
        });
        this.imagesArray = shuffle(imgArray)
        this.searchLoading = false;
        this.pageLoading = false;
      }


    },
  },

  watch: {
    '$route': {
      async handler() {
        if (this.$route.path !== '/projects') {
          this.pageLoading = true;
        }

        if (this.$route.query.category) {
          this.selectedCategory = await this.$route.query.category
        } else {
          this.selectedCategory = await this.$route.query.category
        }

        await this.getAllIds()

        await this.getProjects()
      },
      deep: true,

    }
  },

  computed: {

    pageLoading: {
      get() {
        return this.$store.state.pageLoading;
      },
      set(value) {
        this.$store.commit("UPDATE_PAGE_LOADING", value);
      },
    }

  }
};
</script>

<style scoped lang="scss">
.page {
  padding-bottom: 130px;
}

.projects-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 300px));
  gap: 50px;
  padding: 0 50px;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  width: 100vw;
}

.categories-wrapper {
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
  width: 100vw;
  flex-wrap: wrap;
  margin-top: 20px;
}

.category {
  display: flex;
  padding: 8px 12px;
  color: $dark-color;
  font-weight: 500;
  min-width: 80px;
  justify-content: center;
  align-items: center;
  border: 1px solid $dark-color;
  background: transparent;
}

.category-active {
  background: $dark-color;
  color: $light-color;
}

.project-element {
  display: flex;
  flex-direction: column;
  width: 300px;
  height: 300px;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;


}

.project-image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 300px;
  overflow: hidden;
  position: relative;
  transition: all 0.3s ease-in-out;

  &:hover {
    .project-image {
      transform: scale(1.1);

    }

    .project-title {
      opacity: 1;
    }
  }
}

.project-image {
  object-fit: cover;
  background: $gray-color;
  transition: all 0.5s ease-in-out;
  filter: saturate(150%)
}

.project-title {
  display: flex;
  width: 60%;
  color: $light-color;
  text-transform: capitalize;
  min-height: 70px;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: 700;
  position: absolute;
  opacity: 0;
  transition: all 1s ease-in-out;
  background: $dark-color;

}

.projectAnimation {
  animation-duration: 0.6s;
  /* the duration of the animation */
  animation-timing-function: ease;
  /* how the animation will behave */
  animation-delay: 0s;
  /* how long to delay the animation from starting */
  animation-iteration-count: 1;
  /* how many times the animation will play */
  animation-name: projectAnimation;
  /* the name of the animation we defined above */
}

@keyframes projectAnimation {
  0% {
    filter: grayscale(1);
    opacity: 0;
  }

  100% {
    filter: grayscale(0);

    opacity: 1;

  }
}

.no-project {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 40px 0;
  color: $text-color;
  position: absolute;
  top: 50%;
}

// .project-bottom-line{
//     display: flex;
//     width: 100%;
// margin-top: auto;
// height: 24px;
// background: whitesmoke;
// }
</style>
