<template>
  <div class="contact-wrapper page">
    <div class="mapouter">
      <div class="gmap_canvas">

        <iframe frameborder="0" scrolling="no" style="overflow: hidden; height: 100%; width: 100%" marginheight="0"
          marginwidth="0"
        src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Skyport%20Residance+(Seda%20Deniz%20Mimarl%C4%B1k)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
      </div>
    </div>
    <div class="contact-inputs-wrapper">
      <a class="contact-adress " target="_blank"
        href="https://www.google.com/maps?ll=41.007506,28.661525&z=14&t=m&hl=en&gl=US&mapclient=embed&cid=3436803583086125355">
        <MapMarker></MapMarker>
        <div>
          {{ $t("home.address") }}

        </div>

      </a>

      <a href="tel:+902129360899" class="contact-adress ">
        <Phone></Phone>
        <div>
          +90 212 936 08 99

        </div>

      </a>

      <a href="mailto: sedadenizbahadir@gmail.com" class="contact-adress ">
        <Email></Email>
        <div>
          sedadenizbahadir@gmail.com

        </div>

      </a>

    </div>
  </div>
</template>

<script>
import Phone from "vue-material-design-icons/Phone.vue";
import MapMarker from "vue-material-design-icons/MapMarker.vue";
import Email from "vue-material-design-icons/Email.vue";



export default {
  components: {
    MapMarker,
    Phone, Email

  }

};
</script>

<style scoped lang="scss">
.gmap_canvas {
  overflow: hidden;
  background: none !important;
  height: 40vh;
  width: 100%;
  display: flex;
  justify-content: center;
  background: $dark-color;
}

.mapouter {
  position: relative;
  text-align: right;
  height: 40vh;
  width: 100%;
  display: flex;
  justify-content: center;
  background: $dark-color;
}

.contact-wrapper {

  margin-bottom: 90px;
}

.contact-inputs-wrapper {
  display: flex;
  flex-direction: column;
  width: 1200px;
  max-width: 98%;
  margin: 12px auto;
  align-items: center;
  color: $dark-color;
  text-align: center;
}

.contact-adress {
  margin: 8px;
  display: flex;
  width: 100%;
  height: 60px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  transition: 0.3s ease all;
  color: $dark-color;
  box-shadow: 0 1px 3px 0.5px rgb(43, 43, 43, 0.3);
  *{
    color: $primary-color;
  }

  div{
    color: $dark-color;
  }
}

.contact-adress:hover {
  box-shadow: 0 1px 3px 0.5px rgb(43, 43, 43, 0.6) !important;
}


.bg-error {
  background: rgb(136, 79, 79);

}

.bg-warning {
  background: orange;

}

.bg-success {
  background: rgb(81, 233, 81);
}

@media only screen and (max-width: 900px) {

  .contact-wrapper {
    min-height: 100vh;

  }
}</style>
