import { firebaseConfig } from "./firebaseConfig";
import { initializeApp } from "firebase/app";
import {
  getFirestore,
  getDoc,
  doc,
  collection,
  getDocs,
  where,
  query,
} from "firebase/firestore";
import { getStorage } from "firebase/storage";

const app = initializeApp(firebaseConfig);
const storage = getStorage();
const db = getFirestore();

const getProjectIds = async () => {
  const docRef = doc(db, "general", "ids");
  const docSnap = await getDoc(docRef);

  return docSnap.exists() ? docSnap.data() : null;
};

const getProjectCategoryIds = async (category) => {
  const result = [];
  let quesRef = query(
    collection(db, "projects"),where("category", "==", category)
  );

  const querySnapshot = await getDocs(quesRef);
  querySnapshot.forEach((doc) => {
    let quest = doc.data();
    console.log(quest)
    result.push(quest.id);
  });
  return result;
};

const getProjectsByIds = async (ids) => {
  let result = {
    results: [],
    totalResults: 0,
  };

  for (let i = 0; i < ids.length; i++) {
    let quesRef = null;

    quesRef = query(collection(db, "projects"), where("id", "==", ids[i]));

    const querySnapshot = await getDocs(quesRef);
    querySnapshot.forEach((doc) => {
      let quest = doc.data();
      quest.activeHintNumber = 0;
      result.results.push(quest);
    });
  }
  return result;
};

const getDocument = async (col, id) => {
  const updateRef = doc(db, col, id);
  const docSnap = await getDoc(updateRef);

  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    // doc.data() will be undefined in this case
    console.log("No such document!");
  }
};

const getProjectWithId = async (id) => {
  id = parseInt(id)
  const projectRef = query(collection(db, "projects"), where("id", "==", id));
  const result = [];
  const querySnapshot = await getDocs(projectRef);
  await querySnapshot.forEach((doc) => {
    result.push(doc.data());
  });


  return result;
};

export { app, storage, db, getProjectIds, getProjectsByIds, getDocument,getProjectWithId,getProjectCategoryIds };
