<template>
  <div class="carousel-wrapper" v-if="carousel">
    <div class="carousel-title">
<div class="carousel-main-title">
  SEDA<br>DENİZ
</div>
<div class="carousel-subtitle">
{{$t('home.architecture')}}  &
{{$t('home.design')}} 

</div>
<div class="carousel-subtitle">
</div>
    </div>

    <img
      v-for="(project, index) in projects"
      :key="index"
      :class="[
        isFront == index ? 'front-image' : '',
        nextImage == index ? 'next-image' : '',
      ]"
      class="carousel-image"
      :src="project.url"
      :alt="'home carousel-'+ index"
      
    />
  </div>
</template>

<script>
import {getDocument} from '../firebase/db.js'

export default {
  data() {
    return {
      carousel:null,
      projects:[],
      isFront: 0,
      frontInterval: false,
    };
  },
  computed: {
    nextImage: function () {
      if (this.isFront < this.projects.length - 1) {
        return this.isFront + 1;
      } else return 0;
    },
      pageLoading: {
      get() {
        return this.$store.state.pageLoading;
      },
      set(value) {
        this.$store.commit("UPDATE_PAGE_LOADING", value);
      },
    },
  },

 

  created() {
    this.getCarousel()
    this.frontChange();
  },

  methods: {
    async getCarousel() {
      this.pageLoading = true
      const carousel = await getDocument(`mainpage`, "carousel");
this.carousel = carousel
this.projects = carousel.images
    
      this.pageLoading = false
    },

    changeFrontImage() {
      if (this.isFront < this.projects.length - 1) {
        this.isFront += 1;
      } else this.isFront = 0;
    },

    frontChange() {
      setInterval(
        () => this.changeFrontImage(),

        5000
      );
    },
  },
};
</script>

<style scoped lang="scss">
.carousel-wrapper {
  display: flex;
  width: 100vw;
  height: calc(100vh - 92px);
  position: relative;
  overflow: hidden;
}
.carousel-image-wrapper {
  display: flex;
  width: 100vw;
  height: calc(100vh - 92px);
  position: absolute;
  top: 0%;
  left: 0;
  overflow: hidden;
}

.carousel-image {
  display: flex;
  width: 100vw;
  height: calc(100vh - 92px);
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

.front-image {
  z-index: 2;
  animation: zoomin 5s infinite ease;
}
.next-image {
  z-index: 1;
}

.carousel-title {
  display: flex;
  width: 100vw;
  height: calc(100vh - 92px);
  flex-direction: column;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 4;
  color: white;

}
.carousel-main-title{
  font-size: 8rem !important;
border: 1rem solid white;
padding: 1rem;

}
.carousel-subtitle{
  font-size: 3.5rem;
  font-weight: bold;

}

@keyframes zoomin {
  0% {
    transform: scale(1, 1);
    opacity: 1;
  }
  75% {
    opacity: 1;
  }

  100% {
    transform: scale(1.2, 1.2);
    opacity: 0;
  }
}

@media only screen and (max-width: 900px) {
 .carousel-title{
     font-size: 7rem;

  }
}

@media only screen and (max-width: 500px) {
 .carousel-title{
     font-size: 4rem;

  }
}
</style>
