import "./assets/css/main.scss";

import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import titleMixin from "./mixins/titleMixin";
import i18n from "./i18n";



createApp(App).mixin(titleMixin).use(store).use(i18n).use(router).mount("#app");
