import { createStore } from 'vuex'

export default createStore({
  state: {
    pageLoading: false,

  },
  mutations: {
    UPDATE_PAGE_LOADING(state, value) {
      state.pageLoading = value;
    },
  },
  actions: {
    updatePageLoading({ commit }, value) {
      commit("UPDATE_PAGE_LOADING", value);
    },
  },
  modules: {
  }
})
