<template>
  <div class="topbar-wrapper">
    <a href="tel:+902129360899" class="topbar-phone">
      <Phone :width="6" :height="6"></Phone>
      +90 212 936 08 99
    </a>
    <div class="topbar">
      <a class="topbar-element" href="https://www.facebook.com/Sdbmimarlik/" target="_blank">
        <Facebook></Facebook>
      </a>
      <a class="topbar-element" href="https://www.instagram.com/sedadenizdesign" target="_blank">
        <Instagram></Instagram>
      </a>
      <button @click="isLanguagesModal = !isLanguagesModal" class="navbar-languages">
        
      <Web></Web>
    </button>
    </div>
    
    <LanguagesModal @closeLanguagesModal="isLanguagesModal = false" v-if="isLanguagesModal" name="languages">
    </LanguagesModal>
  </div>
</template>

<script>
import Facebook from "vue-material-design-icons/Facebook.vue";
import Instagram from "vue-material-design-icons/Instagram.vue";
import Phone from "vue-material-design-icons/Phone.vue";
import Web from "vue-material-design-icons/Web.vue";

import LanguagesModal from "./LanguagesModal.vue";

export default {
  data() {
    return {
      isLanguagesModal: false,
    };
  },
  components: {
    Facebook,
    Instagram,
    Web,
    Phone,
    LanguagesModal,
  },
};
</script>

<style lang="scss" scoped>
.topbar-wrapper {
  position: relative;
  display: flex;
  width: 100%;
  max-width: 100vw;
  padding:0 12px;

}

.topbar {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  margin-left:auto;
  z-index: 10;
  gap:8px;
  * { 
    display: flex;
    width: 20px;
    height: 20px;
    justify-content: center;
    align-items: center;
  }
}

.topbar-element {
   color: $primary-color;
   transition: 0.5s all ease;


}

.topbar-element:hover {
  cursor: pointer;
  color: $dark-color;


}

.topbar-phone {
  position: absolute;
  top: 0;
  left: 1%;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  color: $primary-color;
  transition: 0.5s all ease;

  * {
    display: flex;
    width: 20px;
    height: 20px;
    justify-content: center;
    align-items: center;
  }
}

.topbar-phone:hover {
  cursor: pointer;
  color: $dark-color;


}

.navbar-languages {
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 11;
  border-radius: 50%;
  background: transparent;
  border: none;
  color: $primary-color;

  * {
    display: flex;
    width: 20px;
    height: 20px;
    justify-content: center;
    align-items: center;
  }

  transition: 0.5s all ease;
}

.navbar-languages:hover {
  cursor: pointer;
  color: $dark-color;
}

@media only screen and (max-width: 900px) {
  .topbar-phone {
    font-size: 12px;
    height: 40px;


  }

  .topbar {
    position: unset;
    justify-content: left;
  }
}
</style>
